.estilo-colunas {
    font-size: 12px !important;
}

.label-info {
    font-weight: bold;
}

.image {
    max-width: 512px;
    max-height: 512px;
}