.content-right {
    display: flex;
    justify-content: right;
    align-items: center;
}

.card-style {
    width: 512px !important;
    height: 256px !important;
}

.custom-card {
    padding-top: 3%;
    padding-left: 24px;
}

.tile-content {
    padding-top: 5%;
    font-size: 14px;
}

.column-style {
    display: flex;
    padding: 1.75rem;
}

img {
    width: 200px !important;
    max-height: 220px;
}
.active-menu {
  background-color: #f2f2f2;
  font-weight: bold;
}

.navbar-menu {
  background-color: whitesmoke;
}

.App {
}
.estilo-colunas {
    font-size: 12px !important;
}

.label-info {
    font-weight: bold;
}

.image {
    max-width: 512px;
    max-height: 512px;
}
